<template>
  <div>
    <router-view v-show='this.$route.matched.length == 3'></router-view>
    <PageHeaderLayout v-show='this.$route.matched.length == 2'>
      <div class='main-page-content'>
        <div class='table-search'>
          <el-form :model='searchCondition' inline size='mini'>
            <el-form-item label='上级角色'>
              <el-select v-model='searchCondition.pid' filterable clearable @change='handleQuery'>
                <el-option :label='roleOption.display_name' :value='roleOption.id'
                           v-for='(roleOption) in roleQueryOptions'
                           :key='roleOption.id'></el-option>
              </el-select>
              <!--              <el-input v-model='searchCondition.pid' placeholder='归属上级角色' clearable></el-input>-->
            </el-form-item>
            <el-form-item label='角色标识'>
              <el-input v-model='searchCondition.name' placeholder='角色唯一标识' clearable @change='handleQuery'></el-input>
            </el-form-item>
            <el-form-item label='角色名称'>
              <el-input v-model='searchCondition.display_name' placeholder='角色名称' clearable
                        @change='handleQuery'></el-input>
            </el-form-item>
            <el-form-item label='创建时间'>
              <el-date-picker
                v-model='searchCondition.date_range'
                type='daterange'
                align='right'
                unlink-panels
                range-separator='至'
                start-placeholder='开始日期'
                end-placeholder='结束日期'
                value-format='yyyy-MM-dd'
                :picker-options='pickerOptions' @change='handleQuery'>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' :disabled='loadingStatus' :loading='loadingStatus'
                         @click='handleQuery'>搜索
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-row class='table-header'>
          <el-col :span='1.5'>
            <el-button type='primary' size='mini' icon='el-icon-plus' @click='addButton(0)'>新增</el-button>
          </el-col>
        </el-row>
        <div class='default-table'>
          <el-table :data='rolesList' highlight-current-row border>
            <el-table-column label='序号' type='index' align='center' width='100'></el-table-column>
            <!--            <el-table-column label='角色ID' prop='id' align='center' width='200'></el-table-column>-->
            <el-table-column label='角色名称' prop='display_name' align='center' width='260'
                             sortable='custom'></el-table-column>
            <el-table-column label='角色标识' prop='name' align='center' width='200' sortable='custom'></el-table-column>
            <el-table-column label='备注' prop='description' align='left' header-align='center'></el-table-column>
            <el-table-column label='下级角色' align='left' header-align='center'>
              <template slot-scope='{row}'>
                <el-tag
                  style='margin: 5px'
                  v-for='tag in row.children'
                  :key='tag.id'
                  size='small'
                  @close='handleTagClose(tag,row)'
                  closable
                  type='success' effect='plain'>
                  {{ tag.display_name }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label='操作时间' prop='created_at' width='280' align='center'>
              <template slot-scope='{row}'>
                <span>{{ row.created_at || '' }}/{{ row.updated_at || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column width='300' align='center' label='操作'>
              <template slot-scope='scope'>
                <el-button size='mini' v-if="scope.row.id !== 1&&userPermissions.indexOf('roles_edit') !== -1"
                           icon='el-icon-edit'
                           @click='editButton(scope.row.id)' type='text'>修改
                </el-button>
                <el-tooltip v-else content='系统内置禁止修改' placement='top'>
                  <el-button size='mini' icon='el-icon-edit' type='text' title='' disabled>禁止
                  </el-button>
                </el-tooltip>

                <el-button size='mini' icon='el-icon-set-up'
                           @click='permissionsBinding(scope.row)' type='text'>权限绑定
                </el-button>
                <el-button size='mini' icon='el-icon-user'
                           @click='manageMembers(scope.row)' type='text'>成员管理
                </el-button>

                <el-popover v-if="userPermissions.indexOf('roles_delete') != -1" placement='top' width='150'
                            v-model='scope.row.visible'>
                  <p>确定要删除记录吗？</p>
                  <div style='text-align: right; margin: 0'>
                    <el-button type='text' size='mini' @click='scope.row.visible = false'>取消</el-button>
                    <el-button type='danger' size='mini' @click='deleteButton(scope.row.id)'>确定</el-button>
                  </div>
                  <el-button slot='reference' type='text' icon='el-icon-delete' size='mini'>删除</el-button>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
          <m-pagination :limit.sync='pagingData.page_size'
                        :page.sync='pagingData.current_page'
                        :total.sync='pagingData.total'
                        :hidden='pagingData.total===0'
                        @pagination='getList'></m-pagination>
        </div>
        <div>

        </div>
      </div>
    </PageHeaderLayout>

    <ModalDialog :dialogData='dialogData' @dialogConfirm='handleConfirm' @dialogClose='dialogClose'>
      <template slot='content'>
        <el-form :model='formData' size='small' :rules='rules' ref='rolesForm' label-position='right'
                 label-width='96px'>
          <el-form-item label='上级角色'>
            <el-select v-model='formData.pid' filterable clearable>
              <el-option :label='roleOption.display_name' :value='roleOption.id' v-for='(roleOption) in roleOptions'
                         :key='roleOption.id'></el-option>
            </el-select>
            <el-button type='text' icon='el-icon-refresh' @click='loadOptions(0)'>刷新</el-button>
          </el-form-item>
          <el-form-item label='角色名称' prop='display_name'>
            <el-input v-model='formData.display_name'></el-input>
          </el-form-item>
          <el-form-item label='角色标识' prop='name'>
            <el-input v-model='formData.name'></el-input>
          </el-form-item>
          <el-form-item label='备注描述' prop='description'>
            <el-input type='textarea' v-model='formData.description'></el-input>
          </el-form-item>

        </el-form>
      </template>
    </ModalDialog>

    <ApeDrawer :drawerData='drawerData' @drawerClose='drawerClose' @drawerConfirm='drawerConfirm'>
      <template slot='ape-drawer'>
        <div class='drawer-roles-premissions'>
          <div style='padding-left: 10px'>
            <el-checkbox v-model='deptExpand' @change='handleCheckedTreeExpand($event)'>展开/折叠</el-checkbox>
            <el-checkbox v-model='deptNodeAll' @change='handleCheckedTreeNodeAll($event)'>全选/全不选</el-checkbox>
          </div>
          <div style='margin-top: 10px'>
            <el-tree
              class='tree-border'
              :data='permissionsList'
              :render-after-expand='false'
              show-checkbox
              ref='permissionsList'
              node-key='id'
              empty-text='加载中，请稍后'
              :props='permissionProps'
              highlight-current
            ></el-tree>
          </div>
        </div>
      </template>
    </ApeDrawer>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ModalDialog from '@/components/ModalDialog'
import ApeDrawer from '@/components/ApeDrawer'
import { mapGetters } from 'vuex'

export default {
  name: 'RolesList',
  components: {
    PageHeaderLayout,
    ApeTable,
    ModalDialog,
    ApeDrawer
  },
  data() {
    return {
      loadingStatus: true,
      dialogData: {
        visible: false,
        title: '',
        width: '600px',
        loading: true,
        modal: false
      },
      columns: [
        {
          title: 'ID',
          value: 'id',
          width: 60
        },
        {
          title: '角色名称',
          value: 'display_name',
          width: 160
        },
        {
          title: '角色标识',
          value: 'name',
          width: 200
        },
        {
          title: '备注',
          value: 'description',
          width: 300,
          align: 'center'
        }
      ],
      // 表格列表数据
      rolesList: [],
      searchCondition: { date_range: [], pid: null, name: null, display_name: null, description: null },
      orderBy: { id: 'asc' },
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        page_size: 15,
        current_page: 1,
        total: 0
      },
      // 表单结构
      formData: {
        name: '',
        display_name: '',
        description: '',
        pid: null
      },
      roleQueryOptions: [
        { id: 0, display_name: '顶级角色' }
      ],
      roleOptions: [
        { id: 0, display_name: '顶级角色' }
      ],
      // 表单验证
      rules: {
        display_name: [{ required: true, message: '输入角色名称', trigger: 'blur' }],
        name: [{ required: true, message: '输入角色标识', trigger: 'blur' }]
      },
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '权限绑定',
        width_height: '850px'
        // mask: false,
        // close_name: '关 闭',
        // confirm_name: '打 印',
      },
      permissionsList: [],
      rolePermissions: [],
      currentRoleId: null,
      permissionProps: {
        children: 'children',
        label: 'display_name'
      },
      deptExpand: false,
      deptNodeAll: false
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  methods: {
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field] != null && this.searchCondition[field] !== '') {
          condition[field] = this.searchCondition[field]
        }
      })
      // 分页 +排序
      Object.assign(condition, {
        page_size: this.pagingData.page_size,
        current_page: this.pagingData.current_page
      }, { orderBy: this.orderBy })

      return condition
    },
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()
    },
    async getList() {
      let params = this.handleSearchCondition()
      this.loadingStatus = true
      // Object.assign(params, { page_size: this.pagingData.page_size, current_page: this.pagingData.current_page })
      let { list, pages } = await this.$api.getRolesList(params)
      this.rolesList = list
      this.pagingData.total = pages.total || 0
      this.pagingData.current_page = pages.current_page || 1
      this.pagingData.page_size = pages.page_size || 10
      this.loadingStatus = false
    },
    async loadOptions(id, mod) {
      //加载角色可选数据
      let { options } = await this.$api.getRoleOptionsExclude(id)
      if (mod !== 'query') {
        this.roleOptions = [{ id: 0, display_name: '顶级角色' }, ...options]
      } else
        this.roleQueryOptions = [{ id: 0, display_name: '顶级角色' }, ...options]

    },

    // 响应添加按钮
    async addButton() {
      await this.loadOptions(0, 'add')
      this.dialogData.visible = true
      this.dialogData.title = '添加角色'
      this.dialogData.loading = false
    },
    // 响应编辑按钮
    async editButton(id) {
      this.dialogData.visible = true
      this.dialogData.title = '编辑角色'
      await this.loadOptions(id, 'edit')
      //加载详情
      let { info } = await this.$api.getRolesInfo(id)
      this.formData = info
      this.dialogData.loading = false
    },
    // form数据提交，请求接口
    async formSubmit() {
      let id = await this.$api.saveRoles(this.formData)
      if (id) {
        this.handleQuery()
      }
      this.initFormData()
      this.$message({
        showClose: true,
        message: '保存成功',
        type: 'success',
        duration: 2000
      })

      // this.$router.go(0)
    },
    // 相应删除按钮
    async deleteButton(id) {
      let info = await this.$api.deleteRoles(id)
      if (info == 'ok') {
        let { list } = await this.$api.getRolesList()
        this.rolesList = list
        this.$message.success({
          showClose: true,
          message: '删除成功!',
          type: 'success'
        })
      } else {
        this.$message.error(info)
      }
    },
    // 处理模态框，确定事件
    handleConfirm() {
      // 调用组件的数据验证方法
      this.$refs['rolesForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // 处理模态框，关闭事件
    dialogClose() {
      this.initFormData()
    },
    // 初始化数据
    initFormData() {
      // 初始化form表单
      this.$nextTick(() => {
        this.dialogData.visible = false
        this.dialogData.loading = true
        this.formData = {
          display_name: ''
        }
        this.$refs['rolesForm'].resetFields()
      })
    },
    // 权限绑定
    async permissionsBinding(row) {
      this.drawerData.loading_text = '玩命加载中……'
      this.drawerData.visible = true
      this.currentRoleId = row.id
      this.drawerData.title = '权限绑定' + '（ID' + row.id + '-' + row.display_name + '-' + row.name + '）'
      let { permissions_list, role_permissions } = await this.$api.getRolesPermissions(row.id)
      this.permissionsList = permissions_list
      this.rolePermissions = role_permissions

      let that = this
      let newArr = []
      that.permissionsList = permissions_list
      that.rolePermissions = role_permissions
      that.rolePermissions.forEach(item => {
        that.checked(item, that.permissionsList, newArr)
      })
      that.checkedIds = newArr

      this.$nextTick(() => {
        //this.$refs.permissionsList.setCheckedKeys(this.rolePermissions)
        this.$refs.permissionsList.setCheckedKeys(that.checkedIds)
        this.drawerData.loading = false
      })
    },

    //检测id是否是父级id，并将子级id过滤，运用递归
    checked(id, data, newArr) {
      data.forEach(item => {
        if (item.id === id) {
          if (item.children === undefined || item.children.length < 1) {
            newArr.push(item.id)
          }
        } else {
          if (item.children !== undefined && item.children.length !== 0) {
            this.checked(id, item.children, newArr)
          }
        }
      })
    },

    // 每一个权限组的全选状态
    checkedIndeterminate(v) {
      v.checked_status = false
      if (typeof this.rolePermissions[v.id] === 'undefined') {
        return false
      }
      if (this.rolePermissions[v.id]?.length < v.children?.length && this.rolePermissions[v.id]?.length > 0) {
        return true
      }
      if (this.rolePermissions[v.id]?.length == v.children?.length) {
        v.checked_status = true
      }
      return false
    },
    // 处理点击每个组的全选
    handleCheckAll(v) {
      if (v.checked_status) {
        this.rolePermissions[v.id] = v.children.map((val) => {
          return val.id
        })
      } else {
        this.rolePermissions[v.id] = []
      }
    },
    // 处理抽屉关闭
    drawerClose() {
      this.drawerData.visible = false
      this.drawerData.loading = true
      this.permissionsList = []
      this.rolePermissions = []
      this.deptExpand = false
    },
    // 处理抽屉确认
    async drawerConfirm() {
      this.drawerData.loading_text = '玩命提交中……'
      this.drawerData.loading = true

      // let checkedNodes = this.$refs.permissionsList.getCheckedNodes(false, true).map((i) => i.id)
      // let permissionIds = []
      // for (let i = 0; i < checkedNodes.length; i++) {
      //     permissionIds.push(checkedNodes[i].id)
      // }


      let params = {
        role_id: this.currentRoleId,
        //permissions_id: this.rolePermissions,
        permissions_id: this.$refs.permissionsList.getCheckedNodes(false, true).map((i) => i.id)
      }

      await this.$api.saveRolesPermissions(params)
      this.$nextTick(() => {
        this.drawerData.visible = false
      })
      this.$nextTick(() => {
        this.$message.success({
          showClose: true,
          message: '保存成功!',
          type: 'success'
        })
      })
    },
    // 管理成员
    manageMembers(row) {
      this.$router.push(this.$route.path + '/' + row.id + '/manage-members')
    },

    // 树权限（展开/折叠）
    handleCheckedTreeExpand(value) {
      let permissionsList = this.permissionsList
      for (let i = 0; i < permissionsList.length; i++) {
        this.$refs.permissionsList.store.nodesMap[permissionsList[i].id].expanded = value
      }
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAll(value) {
      this.$refs.permissionsList.setCheckedNodes(value ? this.permissionsList : [])
    },
    // eslint-disable-next-line no-unused-vars
    handleTagClose(childRole, parentRole) {
      // console.log(parentRole)
      // console.log(childRole)
      this.$notify.success(childRole.display_name)
      this.removeRolePid(childRole.id)
    },
    removeRolePid(childRoleId) {
      let data = this.$api.removeRolePid(childRoleId)
      if (data) {
        this.handleQuery()
      }
    }
  },
  mounted() {
    this.handleQuery()
    this.loadOptions(0, 'query')
  }
}
</script>
<style scoped>
.drawer-roles-premissions {
  height: 80vh;
  padding-left: 30px;
  /*border: #00feff 1px dashed;*/
}
</style>
<style lang='stylus'>
.el-button
  margin-right 4px
  margin-bottom 4px

.table-header
  margin-bottom 12px

.drag-handle
  font-size 24px
  cursor pointer

.el-input-group__prepend, .el-input-group__append
  background #ffffff
  padding 0 12px

.el-input-group__append
  color #ffffff
  background #1890ff

.permissions-group-name
  padding-left 10px
  cursor pointer

.permissions-checked
  width 144px
  display inline-block;
  height 40px;
  line-height 40px;
  padding 0 12px 0 12px
  box-sizing border-box
  margin 8px 12px 0 0
  border 1px solid #e8e8e8
  border-radius 5px
  font-size 14px
  overflow hidden

  .el-checkbox
    margin-right 0

.el-popover .el-checkbox-group .el-checkbox
  margin-left 0
  margin-right 12px

.el-tree-node
  padding 4px

.el-tree-node__label
  font-size 16px

</style>
